import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PaymentPage } from "./stripe";

export default function Create_Payment() {
  const navigate = useNavigate();

  // Back function
  const back = () =>
    navigate(-1, {
      state: { ...location.state },
    });

  const location = useLocation();
  const { email } = location.state || {};
  const create_prelaunch = () => {
    navigate("/create_prelaunch", {
      state: { ...location.state },
    });
  };

  return (
    <div className="app container">
      <div className="row controls mb-4">
        <div className="col-3">
          <i onClick={back} className="bi fs-4 bi-arrow-left"></i>
        </div>
        <div className="col-6">
          <div className="action">Übersicht</div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="task-description">
            Wir arbeiten an neuen Abonnements.
          </div>
        </div>
        <div className="col-12">
          <div className="subscription-option">
            <div className="subscription-title">Gold</div>
            <div className="subscription-pricing">
              <div className="subscription-price">CHF 12</div>
              <div className="subscription-unit">/ Monat</div>
            </div>
            <div className="subscription-information">
              <div className="subscription-information-element">
                <i className="bi bi-check"></i>Verifizierte Profile
              </div>
              <div className="subscription-information-element">
                <i className="bi bi-check"></i>Unlimitiertes Matchen
              </div>
              <div className="subscription-information-element">
                <i className="bi bi-check"></i>Personalisierte Matchanfragen
              </div>
              <div className="subscription-information-element">
                <i className="bi bi-check"></i>Erweiterte Suchfilter
              </div>
              <div className="subscription-information-element">
                <i className="bi bi-check"></i>Unbegrenztes Messaging
              </div>
              <div className="subscription-information-element">
                <i className="bi bi-check"></i>Exklusive Workshops und Webinare
              </div>
              <div className="subscription-information-element">
                <i className="bi bi-check"></i>Premium Support
              </div>
            </div>
          </div>
          <div className="payment">
            <PaymentPage onSuccess={create_prelaunch} email={email} />
          </div>
        </div>
      </div>
    </div>
  );
}
