import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Optional: Add an interceptor to include the access token in requests
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await refreshToken(); // Attempt to refresh the token
        const newAccessToken = localStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] =
          `Bearer ${newAccessToken}`;
        return api(originalRequest); // Retry the original request
      } catch (err) {
        console.error("Auto-refresh failed", err);
        // Handle case where refresh token is invalid
      }
    }
    return Promise.reject(error);
  },
);

export const registerMail = async (email, password) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/api/v1/auth/register-email/",
      {
        email: email,
        password: password,
      },
    );
    if (response?.status === 200) {
      return true;
    } else {
      alert("Server überlastet. Bitte versuche es erneut.");
    }
  } catch (e) {
    if (e?.status === 409) {
      alert("E-Mail-Adresse bereits vergeben.");
    } else {
      alert("Server überlastet. Bitte versuche es erneut.");
    }
  }
  return false;
};

export const verifyMail = async (email, otp) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/api/v1/auth/verify-email/",
      {
        email: email,
        verification_code: otp,
      },
    );
    if (response?.status == 200) {
      return true;
    } else {
      alert("Code ungültig. Bitte überprüfe deine Mail.");
    }
  } catch (error) {
    alert("Code ungültig. Bitte überprüfe deine Mail.");
  }
  return false;
};

export const login = async (email, password) => {
  try {
    const response = await api.post("/api/v1/auth/jwt/create/", {
      email: email,
      password: password,
    });
    if (response?.status == 200) {
      const { access, refresh } = response.data;
      localStorage.setItem("accessToken", access);
      localStorage.setItem("refreshToken", refresh);

      return true;
    } else {
      alert("Login ungültig");
    }
  } catch (error) {
    alert("Login ungültig");
  }
  return false;
};

export const refreshToken = async () => {
  try {
    const refresh = localStorage.getItem("refreshToken");
    const response = await api.post("/api/v1/auth/jwt/refresh/", {
      refresh: refresh,
    });

    // Update access token in local storage
    localStorage.setItem("accessToken", response.data.access);

    console.log("Token refreshed");
  } catch (error) {
    console.error("Token refresh failed:", error.response?.data);
  }
};

export const loadReligions = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/api/v1/profiles/religions",
    );
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const loadInterests = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/api/v1/profiles/interests",
    );
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const updateUser = async (payload) => {
  try {
    const response = await api.patch("/api/v1/profiles/users/", {
      ...payload,
    });
    if (response?.status == 200) {
      return true;
    }
  } catch (e) {
    alert(
      "Benutzer konnte nicht aktualisiert werden. Versuche es später erneut.",
    );
  }
  return false;
};

export const retrieveUser = async () => {
  try {
    const response = await api.get(
      process.env.REACT_APP_API_URL + "/api/v1/profiles/users/",
    );
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return {};
};

export const retrieveProfilePictures = async () => {
  try {
    const response = await api.get(
      process.env.REACT_APP_API_URL + "/api/v1/profiles/pictures/",
    );
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const uploadPicture = async (image) => {
  if (!image) return true;

  const formData = new FormData();
  formData.append("image", image);

  try {
    const response = await api.post("/api/v1/profiles/pictures/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response?.status == 201) {
      return true;
    }
  } catch (e) {
    console.log("Bild konnte nicht hochgeladen werden.");
  }
  return false;
};

export const savePaymentMethod = async (paymentMethodId) => {
  try {
    const response = await api.post(
      "/api/v1/subscriptions/save-payment-method/",
      {
        payment_method_id: paymentMethodId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response?.status == 200) {
      return { success: true, error: [] };
    } else {
      alert(JSON.stringify(response));
      return { success: false, error: [response?.data?.error] };
    }
  } catch (error) {
    alert(JSON.stringify(error));
    return { success: false, error: ["Service nicht erreichbar"] };
  }
};
