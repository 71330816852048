import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUser, retrieveUser } from "../../lib/backendClient";

export default function Create_Name() {
  const navigate = useNavigate();
  const back = () => navigate(-1);
  const [disabled, setActiveButton] = React.useState(true);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const location = useLocation();

  const create_distance = async () => {
    let update = await updateUser({
      first_name: firstName,
      last_name: lastName,
    });
    if (update)
      navigate("/create_gender", {
        state: { ...location.state },
      });
  };

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.first_name) {
        setFirstName(profile?.first_name);
      }
      if (profile?.last_name) {
        setLastName(profile?.last_name);
      }
      if (profile?.first_name && profile?.last_name) {
        setActiveButton(false);
      }
    });
  }, []);

  return (
    <div className="app container">
      <div className="row controls mb-4">
        <div className="col-3">
          <i onClick={back} className="bi fs-4 bi-arrow-left"></i>
        </div>
        <div className="col-6">
          <div
            className="progress"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div className="progress-bar" style={{ width: "40%" }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sign-in w-100 m-auto">
          <img src="" className="mb-4" />
          <h1 className="">Wie heisst du?</h1>
          <div className="task-description">
            Deine Privatsphäre steht bei Paalam an erster Stelle. Dein Nachname
            ist für andere Mitglieder nicht sichtbar.
          </div>
        </div>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Dein Vorname"
            aria-label="Dein Vorname"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              setFirstName(e.target.value);
              if (lastName) setActiveButton(false);
            }}
            value={firstName}
          />{" "}
          <input
            type="text"
            className="form-control mx-2"
            placeholder="Dein Nachname"
            aria-label="Dein Nachname"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              setLastName(e.target.value);
              if (firstName) setActiveButton(false);
            }}
            value={lastName}
          />
        </div>
      </div>
      <div className="row mt-auto">
        <div className="col">
          <button
            disabled={disabled}
            onClick={create_distance}
            className="btn btn-primary w-100 py-2"
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
}
