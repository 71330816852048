import { BrowserRouter, Routes, Route } from "react-router-dom";
import Welcome from "./pages/welcome";
import Register from "./pages/register";
import Login from "./pages/login";
import Mail_Verification_Information from "./pages/mail_verification_information";
import Mail_Verification_Otp from "./pages/mail_verification_otp";
import Create_Profile from "./pages/profile/create";
import Create_Name from "./pages/profile/name";
import Create_Gender from "./pages/profile/gender";
import Create_Birthday from "./pages/profile/birthday";
import Create_Location from "./pages/profile/location";
import Create_Distance from "./pages/profile/distance";
import Create_Religion from "./pages/profile/religion";
import Create_Status from "./pages/profile/status";
import Create_Interests from "./pages/profile/interests";
import Create_Pictures from "./pages/profile/pictures";
import Create_Payment from "./pages/profile/payment";
import Prelaunch from "./pages/profile/prelaunch";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Welcome />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route
            path="mail_verification_information"
            element={<Mail_Verification_Information />}
          />
          <Route
            path="mail_verification_otp"
            element={<Mail_Verification_Otp />}
          />
          <Route path="create_profile" element={<Create_Profile />} />
          <Route path="create_name" element={<Create_Name />} />
          <Route path="create_gender" element={<Create_Gender />} />
          <Route path="create_birthday" element={<Create_Birthday />} />
          <Route path="create_location" element={<Create_Location />} />
          <Route path="create_distance" element={<Create_Distance />} />
          <Route path="create_religion" element={<Create_Religion />} />
          <Route path="create_status" element={<Create_Status />} />
          <Route path="create_interests" element={<Create_Interests />} />
          <Route path="create_pictures" element={<Create_Pictures />} />
          <Route path="create_payment" element={<Create_Payment />} />
          <Route path="create_prelaunch" element={<Prelaunch />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
