import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUser, retrieveUser } from "../../lib/backendClient";

export default function Create_Status() {
  const navigate = useNavigate();
  const back = () => navigate(-1);

  const [disabled, setDisabled] = React.useState(true);
  const [status, setStatus] = React.useState();
  const location = useLocation();

  const create_interests = async () => {
    let update = await updateUser({ marital_status: status });
    if (update)
      navigate("/create_interests", {
        state: { ...location.state },
      });
  };

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.marital_status) {
        setStatus(profile?.marital_status);
        setDisabled(false);
      }
    });
  }, []);

  const options = [
    { title: "noch nie verheiratet", key: "single" },
    { title: "geschieden", key: "divorced" },
    { title: "getrennt", key: "separated" },
    { title: "verwitwet", key: "widow" },
  ];

  return (
    <div className="app container">
      <div className="row controls mb-4">
        <div className="col-3">
          <i onClick={back} className="bi fs-4 bi-arrow-left"></i>
        </div>
        <div className="col-6">
          <div
            className="progress"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div className="progress-bar" style={{ width: "70%" }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sign-in w-100 m-auto">
          <h1>Wie ist dein Familienstand?</h1>
          <div className="task-description">
            Bitte gib deinen Familienstand an. Diese Information hilft dabei,
            ein klareres Bild deiner aktuellen Lebenssituation zu vermitteln.
          </div>
        </div>
        <div className="personal-status">
          {options.map(({ title, key }, index) => (
            <div className="form-check" key={index}>
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id={`flexRadioDefault${key}`}
                onChange={() => {
                  setStatus(key);
                  setDisabled(false);
                }}
                checked={key === status}
              />
              <label
                className="form-check-label"
                htmlFor={`flexRadioDefault${key}`}
              >
                {title}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="row mt-auto">
        <div className="col">
          <button
            disabled={disabled}
            onClick={create_interests}
            className="btn btn-primary w-100 py-2"
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
}
