import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { savePaymentMethod } from "../../lib/backendClient";

// Initialize Stripe

const CheckoutForm = ({ onSuccess, email }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setError("Stripe.js has not loaded yet.");
      setLoading(false);
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement) {
      setError("CardNumberElement is not mounted.");
      setLoading(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
      billing_details: {
        email: email, // You can change this to actual billing info
      },
    });

    if (error) {
      setError(error.message);
      setLoading(false);
      return;
    }

    const result = await savePaymentMethod(paymentMethod.id);

    if (!result.success) {
      setError(result.error);
      setLoading(false);
      return;
    }

    setLoading(false);
    onSuccess();
  };

  // Styling for Card Elements to match your design system
  const cardStyle = {
    style: {
      base: {
        fontSize: "16px",
        color: "var(--bs-gray-700)",
        backgroundColor: "var(--bs-transparent)",
        "::placeholder": {
          color: "#6c757d",
        },
        lineHeight: "40px", // Match the height to vertically center
      },
      invalid: {
        color: "#dc3545", // Use red for invalid input
      },
    },
  };

  const handleCardChange = (elementType, complete) => {
    setCardComplete((prevState) => ({
      ...prevState,
      [elementType]: complete,
    }));

    if (
      cardComplete.cardNumber &&
      cardComplete.cardExpiry &&
      cardComplete.cardCvc &&
      complete
    ) {
      handleSubmit();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Credit card number field */}
      <div className="mb-3">
        <label htmlFor="card-number" className="form-label">
          Kartennummer
        </label>
        <div className="form-control pl-12">
          <CardNumberElement
            id="card-number"
            options={{
              ...cardStyle, // Use your existing cardStyle
              showIcon: true, // Enable the dynamic card icon
              iconStyle: "solid", // Set the style of the icon, can also be 'default'
            }}
            onChange={(e) => handleCardChange("cardNumber", e.complete)}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6">
          {/* Expiry date field */}
          <label htmlFor="card-expiry" className="form-label">
            Ablaufdatum
          </label>
          <div className="form-control pl-12">
            <CardExpiryElement
              id="card-expiry"
              options={cardStyle}
              onChange={(e) => handleCardChange("cardExpiry", e.complete)}
            />
          </div>
        </div>
        <div className="col-6">
          {/* CVV field */}
          <label htmlFor="card-cvc" className="form-label">
            Sicherheitscode
          </label>
          <div className="form-control pl-12">
            <CardCvcElement
              id="card-cvc"
              options={cardStyle}
              onChange={(e) => handleCardChange("cardCvc", e.complete)}
            />
          </div>
        </div>
      </div>

      <div className="row mt-auto">
        <div className="col">
          <button
            type="submit"
            className="btn btn-primary w-100 py-2"
            disabled={
              !cardComplete.cardNumber ||
              !cardComplete.cardExpiry ||
              !cardComplete.cardCvc ||
              loading
            }
          >
            {loading
              ? "Kartendaten werden geprüft..."
              : "Kartendaten speichern"}
          </button>

          {error && <div className="text-danger mt-2">{error}</div>}
        </div>
      </div>
    </form>
  );
};

export const PaymentPage = ({ onSuccess, email }) => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  );
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm onSuccess={onSuccess} email={email} />
    </Elements>
  );
};
