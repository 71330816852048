import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  loadInterests,
  updateUser,
  retrieveUser,
} from "../../lib/backendClient";

export default function Create_Interests() {
  const navigate = useNavigate();
  const back = () => navigate(-1);

  const [disabled, setDisabled] = React.useState(true);
  const [interests, setInterests] = React.useState(new Set());

  const location = useLocation();

  const create_pictures = async () => {
    let update = await updateUser({ interests: [...interests] });
    if (update)
      navigate("/create_pictures", {
        state: { ...location.state },
      });
  };

  const addInterest = (interest) => {
    setInterests((prevInterests) => {
      const updatedInterests = new Set(prevInterests);

      if (updatedInterests.has(interest)) {
        updatedInterests.delete(interest);
      } else {
        updatedInterests.add(interest);
      }

      setDisabled(updatedInterests.size === 0);
      return updatedInterests;
    });
  };

  const filter = (event) => {
    setFilteredOptions(
      avaliableOptions.filter((option) =>
        option.name.startsWith(event.target.value),
      ),
    );
  };

  const [avaliableOptions, setAvailableOptions] = React.useState([]);
  const [filteredOptions, setFilteredOptions] = React.useState([]);

  useEffect(() => {
    loadInterests().then((interests) => {
      setAvailableOptions(interests);
      setFilteredOptions(interests);
    });
    retrieveUser().then((profile) => {
      if (profile?.interests) {
        setInterests(new Set(profile?.interests));
        setDisabled(false);
      }
    });
  }, []);

  return (
    <div className="app container">
      <div className="row controls mb-4">
        <div className="col-3">
          <i onClick={back} className="bi fs-4 bi-arrow-left"></i>
        </div>
        <div className="col-6">
          <div
            className="progress"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div className="progress-bar" style={{ width: "80%" }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sign-in w-100 m-auto">
          <img src="" className="mb-4" />
          <h1 className="">Finde Personen mit ähnlichen Interessen</h1>
          <div className="task-description">
            Erzähle uns von deinen Interessen.
          </div>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id="mail">
            <i className="bi bi-search"></i>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Interessen suchen"
            aria-label="interests"
            aria-describedby="interests"
            onChange={filter}
          />
        </div>
        <div className="hstack flex-wrap mt-2 gap-2">
          {filteredOptions.map((option, index) => (
            <button
              key={index}
              className={`btn btn-${interests.has(option.id) ? "primary" : "secondary"} py-2`}
              onClick={() => addInterest(option.id)}
            >
              {option.name} {option.symbol}
            </button>
          ))}
        </div>
      </div>
      <div className="row mt-auto">
        <div className="col">
          <button
            disabled={disabled}
            onClick={create_pictures}
            className="btn btn-primary w-100 py-2"
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
}
