import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Welcome() {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const register = () => {
    navigate("/register"); // Navigate to the "About" page when button is clicked
  };

  const login = () => {
    navigate("/login"); // Navigate to the "About" page when button is clicked
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);
  if (loading) {
    return (
      <div style={{ background: "#9610FF", color: "#9610FF", height: "100vh" }}>
        Loading
      </div>
    );
  }
  return (
    <div className="d-flex align-items-center welcome">
      <div className="sign-in w-60 m-auto">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="text-center">Paalam</h1>
              <h2>Vom Chat zum Kalyanam</h2>
              <div className="choice-options">
                <button onClick={register} className="btn btn-primary py-2">
                  Registrieren
                </button>
                <button onClick={login} className="btn btn-secondary py-2">
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
