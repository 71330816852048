import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Create_Payment() {
  const navigate = useNavigate();

  const create_prelaunch = () => {
    alert("Prelaunch Erfolgreich abgeschlossen!");
    navigate("/");
  };

  return (
    <div className="app container">
      <div className="row controls mb-4">
        <div className="col-3"></div>
        <div className="col-6">
          <div
            className="progress"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div className="progress-bar" style={{ width: "100%" }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sign-in w-100 m-auto">
          <img src="" className="mb-4" />
          <h1 className="">Willkommen, PLACEHOLDER</h1>
          <div className="task-description">
            Paalam ist eine Community von Tamilinnen und Tamilen in der DACH
            Region.
          </div>
          <div className="task-description">
            Bitte handle im Einklang mit deinen Werten von Respekt und Mitgefühl
            und halte dich an unsere Richtlinien.
          </div>
          <div className="task-description">
            Bei unangemessenem Verhalten wird dein Konto dauerhaft blockiert.
          </div>
        </div>
      </div>
      <div className="row mt-auto">
        <div className="col">
          <button
            onClick={create_prelaunch}
            className="btn btn-primary w-100 py-2"
          >
            Annehmen & Loslegen
          </button>
        </div>
      </div>
    </div>
  );
}
