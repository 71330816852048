import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  loadReligions,
  updateUser,
  retrieveUser,
} from "../../lib/backendClient";

export default function Create_Religion() {
  const navigate = useNavigate();
  const back = () => navigate(-1);

  const [disabled, setDisabled] = React.useState(true);
  const [religion, setReligion] = React.useState();
  const location = useLocation();

  const create_status = async () => {
    let update = await updateUser({ religion: religion });
    if (update)
      navigate("/create_status", {
        state: { ...location.state },
      });
  };

  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    loadReligions().then((religions) => setOptions(religions));
    retrieveUser().then((profile) => {
      if (profile?.religion) {
        setReligion(profile?.religion);
        setDisabled(false);
      }
    });
  }, []);

  return (
    <div className="app container">
      <div className="row controls mb-4">
        <div className="col-3">
          <i onClick={back} className="bi fs-4 bi-arrow-left"></i>
        </div>
        <div className="col-6">
          <div
            className="progress"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div className="progress-bar" style={{ width: "60%" }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sign-in w-100 m-auto">
          <img src="" className="mb-4" />
          <h1 className="">Welcher Religion gehörst du an?</h1>
          <div className="task-description">
            Bitte gib deine Religion ein. Diese Information hilft dabei,
            Übereinstimmungen in Glaubensfragen zu finden.
          </div>
        </div>
        <div className="hstack flex-wrap gap-2">
          {options.map((option, index) => (
            <button
              key={index}
              onClick={() => {
                setReligion(option.id);
                setDisabled(false);
              }}
              className={`btn btn-${religion === option.id ? "primary" : "secondary"} py-2`}
            >
              {option.name} {option.symbol}
            </button>
          ))}
        </div>
      </div>
      <div className="row mt-auto">
        <div className="col">
          <button
            onClick={create_status}
            disabled={disabled}
            className="btn btn-primary w-100 py-2"
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
}
