import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUser, retrieveUser } from "../../lib/backendClient";

export default function Create_Gender() {
  const navigate = useNavigate();
  const back = () => navigate(-1);

  const [gender, setGender] = React.useState(null);
  const updateGender = (value) => setGender(value);

  const location = useLocation();
  const create_birthday = async () => {
    let update = await updateUser({ gender: gender });
    if (update) navigate("/create_birthday", { state: { ...location.state } });
  };

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.gender) {
        setGender(profile?.gender);
      }
    });
  }, []);

  return (
    <div className="app container">
      <div className="row controls mb-4">
        <div className="col-3">
          <i onClick={back} className="bi fs-4 bi-arrow-left"></i>
        </div>
        <div className="col-6">
          <div
            className="progress"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div className="progress-bar" style={{ width: "20%" }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sign-in w-100 m-auto">
          <img src="" className="mb-4" />
          <h1 className="">Bist du eine Frau oder ein Mann?</h1>
        </div>
        <div className="d-grid gap-3">
          <button
            onClick={() => updateGender("W")}
            className={`btn btn-${gender === "W" ? "primary" : "secondary"} w-100 py-2`}
          >
            Frau
          </button>
          <button
            onClick={() => updateGender("M")}
            className={`btn btn-${gender === "M" ? "primary" : "secondary"} w-100 py-2`}
          >
            Mann
          </button>
        </div>
      </div>
      <div className="row mt-auto">
        <div className="col">
          <button
            disabled={gender === null}
            onClick={create_birthday}
            className="btn btn-primary w-100 py-2"
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
}
