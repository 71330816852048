import React from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

function Login() {
  const [email, setMail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleMailChange = (event) => {
    setMail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const navigate = useNavigate();
  const back = () => navigate(-1);

  const login = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v1/auth/jwt/create",
        {
          email: email,
          password: password,
        },
      );
      if (response?.status == 200) {
        alert("Login");
        //navigate("/mail_verification_information", { state: { mail: email } });
      } else {
        alert("Login fehlgeschlagen");
      }
    } catch {
      alert("Login fehlgeschlafen");
    }
  };

  return (
    <div className="app container">
      <div className="row controls mb-4">
        <div className="col-12">
          <div className="controls" onClick={back}>
            <i className="bi fs-4 bi-arrow-left"></i>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sign-in w-100 m-auto">
          <img src="" className="mb-4" />
          <h1 className="">Willkommen zurück</h1>
          <div className="task-description">
            Gib deine E-Mail-Adresse und dein Passwort ein.
          </div>
          <label htmlFor="" className="form-label">
            E-Mail
          </label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="mail">
              <i className="bi bi-envelope-fill"></i>
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="E-Mail"
              aria-label="E-Mail"
              aria-describedby="basic-addon1"
              value={email}
              onChange={handleMailChange}
            />
          </div>
          <label htmlFor="" className="form-label">
            Passwort
          </label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="password">
              <i className="bi bi-lock-fill"></i>
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Passwort"
              aria-label="Passwort"
              aria-describedby="basic-addon1"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="text-center">
            <a href="" onClick={() => alert("Giacomo Passwort vergessen")}>
              Passwort vergessen
            </a>
          </div>
          <hr />
          <div className="d-flex justify-content-center">
            <p className="account-create">Noch kein Account?</p>
            <Link to="/register">Registrieren</Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button
            onClick={() => login()}
            className="btn btn-primary w-100 py-2"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
