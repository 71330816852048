import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Create_Profile() {
  const navigate = useNavigate();

  const location = useLocation();
  const create_name = () => {
    navigate("/create_name", {
      state: { ...location.state },
    });
  };

  return (
    <div className="app container">
      <div className="row controls mb-4">
        <div className="col-3"></div>
        <div className="col-6">
          <div
            className="progress"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div className="progress-bar" style={{ width: "10%" }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sign-in w-100 m-auto">
          <img src="" className="mb-4" />
          <h1 className="">Willkommen bei Paalam!</h1>
          <div className="task-description">
            Du hast dich erfolgreich registriert. Nun möchten wir dich einladen,
            dein Profil auszufüllen.
          </div>
          <div className="task-description">
            Nimm dir bitte etwas Zeit, um dein Profil zu vervollständigen. So
            erhöhen wir die Chance, dass du deine bessere Hälfte findest.
          </div>
        </div>
      </div>
      <div className="row mt-auto">
        <div className="col">
          <button onClick={create_name} className="btn btn-primary w-100 py-2">
            Profil erstellen
          </button>
        </div>
      </div>
    </div>
  );
}
