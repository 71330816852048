import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUser, retrieveUser } from "../../lib/backendClient";

export default function Create_Distance() {
  const navigate = useNavigate();
  const back = () => navigate(-1);

  const [distance, setDistance] = React.useState(300);
  const location = useLocation();

  const create_religion = async () => {
    let update = await updateUser({ distance: distance });
    if (update)
      navigate("/create_religion", {
        state: { ...location.state },
      }); // Navigate to the "About" page when button is clicked
  };

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.distance) {
        setDistance(profile?.distance);
      }
    });
  }, []);

  return (
    <div className="app container">
      <div className="row controls mb-4">
        <div className="col-3">
          <i onClick={back} className="bi fs-4 bi-arrow-left"></i>
        </div>
        <div className="col-6">
          <div
            className="progress"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div className="progress-bar" style={{ width: "50%" }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sign-in w-100 m-auto">
          <img src="" className="mb-4" />
          <h1 className="">Suchradius festlegen</h1>
        </div>
        <div className="input-group mb-3 distance">
          <label htmlFor="customRange3" className="form-label">
            <div>Distanz</div>
            <div className="distance">{distance}km</div>
          </label>
          <input
            type="range"
            className="form-range"
            min="0"
            max="600"
            step="10"
            id="customRange3"
            value={distance}
            onChange={(e) => setDistance(e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-auto">
        <div className="col">
          <button
            onClick={create_religion}
            className="btn btn-primary w-100 py-2"
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
}
