import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  uploadPicture,
  retrieveProfilePictures,
} from "../../lib/backendClient";

export default function Create_Pictures() {
  const navigate = useNavigate();
  const location = useLocation();

  const [showInfo, setShowInfo] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [images, setImages] = useState(Array(5).fill(null)); // State to store uploaded images

  // Back function
  const back = () =>
    navigate(-1, {
      state: { ...location.state },
    });

  // File input handler
  const handleImageUpload = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImages = [...images];
        newImages[index] = { data: file, dataUrl: reader.result }; // Set the image URL
        setImages(newImages);

        // Enable "Weiter" button if at least 3 images are uploaded
        if (newImages.filter((img) => img !== null).length >= 3) {
          setDisabled(false);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Remove image handler
  const handleRemoveImage = (index) => {
    const fileInput = document.getElementById(`fileInput${index}`);
    fileInput.value = "";
    const newFileInput = fileInput.cloneNode(true);
    newFileInput.addEventListener("change", (event) =>
      handleImageUpload(index, event),
    );
    fileInput.parentNode.replaceChild(newFileInput, fileInput);

    const newImages = [...images];
    newImages[index] = null; // Clear the image
    setImages(newImages);

    // Disable "Weiter" button if less than 3 images remain
    if (newImages.filter((img) => img !== null).length < 3) {
      setDisabled(true);
    }
  };

  // Handle "Weiter" button click (POST request)
  const checkout = async () => {
    const uploadQueue = images.map(
      async (image) => await uploadPicture(image?.data),
    );
    const uploadResults = await Promise.all(uploadQueue);
    const successfulUpload = uploadResults.every((result) => result === true);
    if (successfulUpload)
      navigate("/create_payment", {
        state: { ...location.state },
      });
  };

  useEffect(() => {
    retrieveProfilePictures().then((data) => {
      if (data.length > 0) {
        setImages(
          images.map((_, index) => {
            if (data[index]) {
              return {
                dataUrl: `${process.env.REACT_APP_BACKEND_HOST}${data[index].image}`,
              };
            }
            return null; // Keep null if there's no data for this index
          }),
        );
        //setDisabled(false);
      }
    });
  }, []);

  return (
    <div className="app container">
      <div className="row controls mb-4">
        <div className="col-3">
          <i onClick={back} className="bi fs-4 bi-arrow-left"></i>
        </div>
        <div className="col-6">
          <div
            className="progress"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div className="progress-bar" style={{ width: "90%" }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sign-in w-100 m-auto">
          <img src="" className="mb-4" />
          <h1 className="">Fotos hochladen</h1>
          <div className="task-description">
            Du musst mindestens 3 Fotos hochladen, um dein Profil zu
            vervollständigen. Du kannst sie später ändern.
          </div>
        </div>
      </div>
      <div className="row g-2">
        {images.map((image, index) => (
          <div key={index} className="col-4 picture-element">
            {image && (
              <div className="picture-delete">
                <i
                  className="bi bi-x"
                  onClick={() => handleRemoveImage(index)}
                ></i>
              </div>
            )}
            <div
              className="picture"
              style={{
                backgroundImage: image ? `url(${image.dataUrl})` : "",
                backgroundSize: "cover", // Auto-scale and crop the image to fill the element
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              onClick={() =>
                document.getElementById(`fileInput${index}`).click()
              }
            >
              {!image && <i className="bi bi-plus-lg"></i>}
            </div>
            <input
              type="file"
              id={`fileInput${index}`}
              style={{ display: "none" }}
              onChange={(event) => handleImageUpload(index, event)}
            />
          </div>
        ))}
        <div class="col-4">
          <div class="picture" onClick={() => setShowInfo(!showInfo)}>
            <i class="bi bi-info-lg"></i>
          </div>
        </div>

        {showInfo && (
          <div class="col-12">
            <div class="picture-policies">
              <strong>Richtlinien für das Foto:</strong>
              <br />
              Verwende ein kürzlich aufgenommenes Foto, das dein aktuelles
              Aussehen widerspiegelt.
              <br />
              Das Foto sollte klar und gut beleuchtet sein, ohne Unschärfen oder
              Pixelation. Dein Gesicht soll klar erkennbar sein.
              <br />
              Stelle sicher, dass nur du auf dem Foto zu sehen bist.
              <br />
              Trage angemessene und respektvolle Kleidung, die zu einem seriösen
              Profil passt.
              <br />
              Verwende einen neutralen Hintergrund, um Ablenkungen zu vermeiden
              und den Fokus auf dich zu richten.
              <br />
            </div>
          </div>
        )}
      </div>
      <div className="row mt-auto">
        <div className="col">
          <button
            disabled={disabled}
            onClick={checkout}
            className="btn btn-primary w-100 py-2"
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
}
