import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { registerMail } from "../lib/backendClient";

export default function Mail_Verification_Information() {
  const location = useLocation();
  let { email, password } = location.state || {};

  const navigate = useNavigate();

  const mail_resend = async () => {
    let mailSent = await registerMail(email, password);
    if (mailSent) {
      alert("Mail erneut versendet");
    }
  };

  const mail_verification_otp = () => {
    navigate("/mail_verification_otp", {
      state: { ...location.state },
      replace: true,
    });
  };

  return (
    <div className="app container">
      <div className="row controls mb-4">
        <div className="col-12">
          <div className="d-none controls">
            <i className="bi fs-4 bi-arrow-left"></i>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sign-in w-100 m-auto">
          <div className="icon icon-mail text-center mb-4">
            <i className="bi-envelope"></i>
          </div>
          <h1 className="text-center">Überprüfe deine E-Mail</h1>
          <div className="task-description text-center mb-2">
            Wir haben dir eine E-Mail an {email ? email : "x"} gesendet. Gib
            diesen Code in der App ein.
          </div>
          <div className="text-center">
            <a className="email-confirmation-resend" onClick={mail_resend}>
              E-Mail erneut versenden.
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <button
            onClick={mail_verification_otp}
            className="btn btn-primary w-100 py-2"
          >
            Ich habe den Code erhalten.
          </button>
        </div>
      </div>
    </div>
  );
}
