import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUser, retrieveUser } from "../../lib/backendClient";

export default function Create_Location() {
  const navigate = useNavigate();
  const back = () => navigate(-1);
  const [disabled, setActiveButton] = useState(true);
  const [userLocation, setUserLocation] = useState("");
  const [userLocationLat, setUserLocationLat] = useState(0.0);
  const [userLocationLng, setUserLocationLng] = useState(0.0);

  const location = useLocation();
  const inputRef = useRef(null);

  // Initialize Google Maps Autocomplete
  useEffect(() => {
    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"],
          componentRestrictions: { country: ["DE", "AT", "CH"] }, // Restrict to DACH region
        },
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          setUserLocation(place.formatted_address);
          setUserLocationLat(
            parseFloat(place.geometry.location.lat().toFixed(7)),
          );
          setUserLocationLng(
            parseFloat(place.geometry.location.lng().toFixed(7)),
          );
          setActiveButton(false);
        }
      });
    }
    retrieveUser().then((profile) => {
      if (profile?.location) {
        setUserLocation(profile?.location);
        setActiveButton(false);
      }
      if (profile?.location_lat) {
        setUserLocationLat(profile?.location_lat);
      }
      if (profile?.location_lng) {
        setUserLocationLng(profile?.location_lng);
      }
    });
  }, []);

  const create_distance = async () => {
    let update = await updateUser({
      location: userLocation,
      location_lat: userLocationLat,
      location_lng: userLocationLng,
    });
    if (update) {
      navigate("/create_distance", {
        state: { ...location.state },
      }); // Navigate to the next page when button is clicked
    }
  };

  return (
    <div className="app container">
      <div className="row controls mb-4">
        <div className="col-3">
          <i onClick={back} className="bi fs-4 bi-arrow-left"></i>
        </div>
        <div className="col-6">
          <div
            className="progress"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div className="progress-bar" style={{ width: "40%" }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sign-in w-100 m-auto">
          <img src="" className="mb-4" />
          <h1 className="">Wo lebst du?</h1>
          <div className="task-description">
            Da die Paalam-Mitglieder aus der DACH-Region kommen, ist dein
            Wohnort eine wichtige Information für die Partnersuche.
          </div>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id="mail">
            <i className="bi bi-search"></i>
          </span>
          <input
            type="text"
            ref={inputRef}
            className="form-control"
            placeholder="Ort eingeben"
            aria-label="mail"
            aria-describedby="basic-addon1"
            value={userLocation}
            onChange={(e) => {
              setUserLocation(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="row mt-auto">
        <div className="col">
          <button
            disabled={disabled}
            onClick={create_distance}
            className="btn btn-primary w-100 py-2"
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
}
