import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUser, retrieveUser } from "../../lib/backendClient";

const calculateAge = (birthday) => {
  if (
    birthday[0].length !== 2 ||
    birthday[1].length !== 2 ||
    birthday[2].length !== 4
  )
    return 0;

  try {
    var diff_ms =
      Date.now() -
      new Date(
        Number(birthday[2]),
        Number(birthday[1]) - 1,
        Number(birthday[0]),
      ).getTime();
    var age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  } catch {
    return 0;
  }
};

export default function Create_Birthday() {
  const navigate = useNavigate();
  const back = () => navigate(-1);
  const [active, setActiveButton] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");

  const dateFields = ["TT", "MM", "JJJJ"];
  const [birthday, setBirthday] = React.useState(new Array(3).fill(""));
  const [age, setAge] = React.useState(0);

  const validateInput = (value, index) => {
    if (index === 0) {
      // DD - Valid day check (01 to 31)
      const day = parseInt(value, 10);
      return day >= 1 && day <= 31;
    } else if (index === 1) {
      // MM - Valid month check (01 to 12)
      const month = parseInt(value, 10);
      return month >= 1 && month <= 12;
    } else if (index === 2) {
      // YYYY - Valid year check (1900 to current year)
      const year = parseInt(value, 10);
      const currentYear = new Date().getFullYear();
      return year >= 1900 && year <= currentYear;
    }
    return false;
  };

  const handleAgeCheck = (newBirthday) => {
    if (newBirthday.every((val) => val.length > 0)) {
      const newAge = calculateAge(newBirthday);
      setAge(newAge);

      if (newAge >= 25) {
        setActiveButton(false);
        setErrorMessage(""); // Clear any error message
      } else {
        setActiveButton(true);
        setErrorMessage(
          "Es tut uns leid, aber unser Dienst richtet sich an Personen ab 25 Jahren.",
        );
      }
    } else {
      // Clear error message if not all fields are filled
      setErrorMessage("");
    }
  };

  const handleChange = (element, index) => {
    const value = element.value.replace(/\D/g, ""); // Ensure only numeric input
    if (value.length > dateFields[index].length) return; // Prevent multiple characters

    // Validate the input based on its position (DD, MM, YYYY)
    if (!validateInput(value, index)) return;

    const newBirthday = [...birthday];
    newBirthday[index] = value; // Update the birthday array
    setBirthday(newBirthday);

    if (
      value &&
      value.length === dateFields[index].length &&
      element.nextSibling &&
      element.nextSibling.value === ""
    ) {
      element.nextSibling.focus();
    } else {
      // Check if all fields are filled
      handleAgeCheck(newBirthday);
    }
  };

  const handleKeyDown = (event) => {
    // Allow only numeric keys, Backspace, Tab, and Arrow keys
    if (
      !/[0-9]/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Tab" &&
      !["ArrowLeft", "ArrowRight"].includes(event.key)
    ) {
      event.preventDefault();
    }
  };

  const location = useLocation();

  const create_location = async () => {
    let update = await updateUser({
      date_of_birth: `${birthday[2]}-${birthday[1]}-${birthday[0]}`,
    });
    if (update) navigate("/create_location", { state: { ...location.state } });
  };

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.date_of_birth && profile?.date_of_birth !== "1900-01-01") {
        const newBirthday = profile?.date_of_birth.split("-").reverse();
        setBirthday(newBirthday);
        handleAgeCheck(newBirthday);
        setActiveButton(false);
      }
    });
  }, []);

  return (
    <div className="app container">
      <div className="row controls mb-4">
        <div className="col-3">
          <i onClick={back} className="bi fs-4 bi-arrow-left"></i>
        </div>
        <div className="col-6">
          <div
            className="progress"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div className="progress-bar" style={{ width: "30%" }}></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sign-in w-100 m-auto">
          <img src="" className="mb-4" />
          <h1 className="">Wann bist du geboren?</h1>
          <div className="task-description">
            Das Alter ist für viele tamilische Singles bei der Partnersuche ein
            wichtiges Kriterium. Dein genaues Geburtsdatum bleibt vertraulich -
            andere Mitglieder sehen lediglich Dein Alter.
          </div>
        </div>
        <div className="input-group birthday mb-3">
          {dateFields.map((date, index) => (
            <input
              key={index}
              type="text"
              className="form-control"
              placeholder={date}
              inputMode="numeric" // Show numeric keyboard on mobile
              pattern="[0-9]*" // Ensures only digits are allowed
              maxLength={date.length}
              onChange={(e) => handleChange(e.target, index)}
              onKeyDown={handleKeyDown} // Restrict key presses
              onFocus={(e) => e.target.select()}
              value={birthday[index] !== "" ? birthday[index] : undefined}
            />
          ))}
        </div>
        {age ? (
          <div className="task-description">
            Alter <strong>{age}</strong> - Diese Angabe kann später nicht
            geändert werden.
          </div>
        ) : (
          ""
        )}
        {errorMessage && (
          <div className="alert alert-danger mt-2" role="alert">
            {errorMessage}
          </div>
        )}
      </div>
      <div className="row mt-auto">
        <div className="col">
          <button
            disabled={active}
            onClick={create_location}
            className="btn btn-primary w-100 py-2"
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
}
